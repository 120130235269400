* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.black {
  height: 20px;
  background-color: black;
}

.bo i {
  font-size: 2rem;
}
.bo {
  display: flex;
  align-items: center;
  justify-content: center;
}
body::-webkit-scrollbar {
  width: 1rem;
  border-radius: 50px;
  background-color: #000000;
}

body::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: #ffffff;
}

body::-webkit-scrollbar-track {
  border-radius: 50px;
  background-color: #000000;
}
/*styling the header*/
.logo {
  font-weight: 600;
  font-size: 2rem;
  color: white;
  margin-left: 20px;
}
.p {
  color: pink;
}
.top {
  background: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.75);
}
.header_right {
  margin-right: 40px;
  color: white;
}
.header_right ul {
  display: flex;
  flex-direction: row;
}
.header_right ul li {
  text-decoration: none;
  list-style: none;
  color: white;
  margin: 10px;
  font-size: 1.2rem;
  padding: 5px;
}
.header_right ul li a {
  color: white;
  text-decoration: none;
}

.header_right ul li i {
  margin-right: 1.5rem;
  font-size: 1.5rem;
}

.backg {
  margin-top: 30px;
  width: 100%;
  height: 500px;
  position: relative;
}

.backg img {
  object-fit: cover;
}
.text {
  position: absolute;
  top: 30%;
  left: 5%;
  display: flex;
  flex-direction: column;
  line-height: 5rem;
}
.texte {
  font-size: 2.5rem;
  font-weight: 600;
}

.texto {
  font-size: 1.5rem;

  font-weight: 500;
}
button {
  background-color: black;
  font-size: 1rem;
  color: white;
  border: 0.8px;
  font-weight: 300;
  height: 4rem;
  padding: 20px;
  width: 200px;
  transition: all 0.2s ease-in-out;
}
button a {
  text-decoration: none;
  color: white;
}
button:hover {
  opacity: 0.6;
}
.down {
  display: flex;
  flex-direction: row;
  height: 450px;
  margin: 80px;
  padding: 35px;
  border-radius: 5px;
  box-shadow: -3px 2px 10px 0.3px rgba(0, 0, 0, 0.2);
}
.left {
  width: 25%;
}
.down .real img {
  object-fit: cover;
}

.right {
  width: 65%;
  margin-left: 30px;
  line-height: 2rem;
  font-size: 1.2rem;
}

.items {
  display: flex;
  padding: 10px;
  align-items: center;
  align-content: center;
}
.v {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
}
h3 {
  padding-right: 5px;
}

.contacttDetails {
  margin-top: 80px;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  align-content: center;
  background: black;
  color: white;

  width: 400px;
  border-radius: 5px;
  justify-content: center;
  justify-items: center;
}

.prod {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.Products h3 {
  margin-left: 30px;
  font-size: 2rem;
  margin: 30px;
}
svg {
  position: relative !important;
  top: 70% !important;
}
iframe {
  width: 180px;
  height: 280px;
  margin: 40px;
}
swiper {
  margin-left: 30px;
}

.Products {
  margin-top: 30px;
}

.container {
  position: relative;
}

.s {
  position: relative;
}
.g {
  position: relative;
  top: -10px;
  z-index: -9999;
}

.overlap {
  color: white;
  position: absolute;
  top: 40%;
}
.overlap span {
  font-weight: 700;
  font-size: 1.5rem;
  padding: 40px;
}

.overlap :nth-child(2) i {
  font-size: 3rem;
}
.t i {
  font-size: 3rem;
  margin-top: 80px;

  align-items: center;
  padding: 15px;
  align-content: center;
}
.first {
  background-color: aquamarine;
  font-weight: bold;
  padding: 10px;
}
.TO {
  font-style: italic;
  font-weight: none;
}
.second {
  background-color: rgba(240, 240, 30, 0.822);
  font-weight: bold;
  padding: 10px;
}
.last {
  background-color: rgba(172, 255, 47, 0.815);
  font-weight: bold;
  padding: 10px;
}
.now {
  display: flex;
  flex-direction: column;
  margin: 3rem;
  padding: 1.5rem;
  line-height: 5rem;
}
.now h3 {
  font-size: 2rem;
}
.now p {
  font-size: 1.2rem;
}
.now i {
  margin-right: 30px;
}
.courses .dean {
  display: flex;
  margin: 3rem;
}
.courses h3 {
  font-size: 2.4rem;
}
.courses i {
  font-size: 2.4rem;
}
.actual {
  display: flex;
}
.bim {
  background-image: url("./tp.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 250px;
  width: 50%;
  margin: 50px;
  color: white;
  position: relative;
}
.bim .ya {
  position: absolute;
  top: 15%;
  left: 5%;
}
.bim .ya h2::first-letter {
  font-size: 1em;
}
.bim .yu {
  position: absolute;
  top: 65%;
  right: 20%;
}
.bimi {
  background-image: url("./p.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 250px;
  width: 50%;
  margin: 50px;
  color: white;
  position: relative;
}

.bimi .ya {
  position: absolute;
  top: 15%;
  left: 5%;
}
.bimi .ya p {
  font-size: 1.5rem;
}
.bimi .ya h2::first-letter {
  font-size: 1em;
}
.bimi .yu {
  position: absolute;
  top: 65%;
  right: 20%;
}
.act {
  display: flex;
}
.blacke {
  background: black;
  color: white;
  width: 70%;
  display: flex;
  height: 250px;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-left: 15%;
}
.blacke i {
  color: white;
  margin-right: 3px;
}
.align {
  justify-content: center;
  align-items: center;
}

.mic {
  background-image: url("./aff.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 250px;
  width: 40%;
  margin: 50px;
  color: white;
  position: relative;
}

.mic .yu {
  position: absolute;
  top: 65%;
  right: 20%;
}
@media screen and (max-width: 768px) {
  .down {
    height: 550px;
  }
  .right {
    width: 40%;
    font-size: 1rem;
    line-height: 25px;
  }
  .right h3 {
    margin: 8px;
  }
  .left {
    width: 48%;
  }
  .overlap span {
    font-weight: 600;
    font-size: 1.08rem;
    padding: 40px;
  }
}
@media screen and (max-width: 328px) {
  * {
    font-size: 15px;
  }
  body {
    width: 100%;
  }

  .down {
    flex-direction: column;
    width: 100%;
    margin: 0px;
    height: 1000px;
  }
  .logo {
    font-size: 1rem;
  }

  .header_right ul li i {
    display: flex;
    margin: 3px;
    padding: 1px;

    font-size: 1rem;
  }
  .header_right ul li {
    margin: 3px;
    font-size: 1rem;
    padding: 5px;
    display: flex;
    flex-direction: row;
  }
  .header_right ul li i a {
    margin: 1px;
  }
  .header_right {
    margin-right: 20px;
  }
  .text {
    line-height: 3rem;
  }
  button {
    padding: 10px;
  }
  .left {
    height: 550px;
    width: 300px;
    margin: 10px;
  }
  .right {
    font-size: 12px;

    width: 100%;
    margin-left: -15px;
  }
  .right h3 {
    margin-top: 20px;
    font-size: 1.3rem;
    display: flex;
  }
  .overlap span {
    font-size: 10px;
    width: 100%;
  }
  .overlap {
    top: 25%;
  }
  .Products h3 {
    font-size: 1.5rem;
  }
  .bo i {
    font-size: 1.5rem;
  }
  .bo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .prod {
    display: flex;
    flex-direction: column;
  }
  .v {
    width: 90%;
  }
  .black {
    height: 20px;
    background-color: black;
  }
  .now {
    line-height: 3rem;
  }
  .blacke {
    font-size: 9px;
    width: 100%;
    margin: 15%;
  }
  .bim {
    width: 100%;
    margin-left: -3px !important;
  }
  .bimi {
    width: 100%;
    margin-left: -3px !important;
  }
  .blacke {
    margin-left: -3px !important;
  }
  .act {
    display: flex;
    flex-direction: column;
  }
  .mic {
    width: 100%;
    margin-left: -3px !important;
  }
  .mic .yu {
    position: absolute;
    top: 75%;
    right: 20%;
  }
  .dean h3 {
    font-size: 2rem;
  }
  .dean i {
    font-size: 2rem;
  }
  .dean {
    margin: 2%;
  }
}
@media screen and (max-width: 500px) {
  * {
    font-size: 15px;
  }
  body {
    width: 100%;
  }

  .down {
    flex-direction: column;
    width: 100%;
    margin: 0px;
    height: 1000px;
  }
  .logo {
    font-size: 1rem;
  }

  .header_right ul li i {
    display: flex;
    margin: 3px;
    padding: 1px;

    font-size: 1rem;
  }
  .header_right ul li {
    margin: 3px;
    font-size: 1rem;
    padding: 5px;
    display: flex;
    flex-direction: row;
  }
  .header_right ul li i a {
    margin: 1px;
  }
  .header_right {
    margin-right: 20px;
  }
  .text {
    line-height: 3rem;
  }
  button {
    padding: 10px;
  }
  .left {
    height: 1000px;
    width: 300px;
    margin: 10px;
  }
  .right {
    font-size: 12px;
    word-wrap: nowrap;
    width: 100%;
    margin-left: -15px;
  }
  .right h3 {
    margin-top: 20px;
    font-size: 1.3rem;
    display: flex;
  }
  .overlap span {
    font-size: 10px;
    width: 100%;
  }
  .overlap {
    top: 25%;
  }
  .Products h3 {
    font-size: 1.5rem;
  }
  .bo i {
    font-size: 1.5rem;
  }
  .bo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .prod {
    display: flex;
    flex-direction: column;
  }
  .v {
    width: 90%;
  }
  .black {
    height: 20px;
    background-color: black;
  }
  .now {
    line-height: 3rem;
  }
  .blacke {
    font-size: 9px;
    width: 100%;
    margin: 15%;
  }
  .bim {
    width: 100%;
    margin-left: -3px !important;
  }
  .bimi {
    width: 100%;
    margin-left: -3px !important;
  }
  .blacke {
    margin-left: -3px !important;
  }
  .act {
    display: flex;
    flex-direction: column;
  }
  .mic {
    width: 100%;
    margin-left: -3px !important;
  }
  .mic .yu {
    position: absolute;
    top: 75%;
    right: 20%;
  }
  .dean h3 {
    font-size: 2rem;
  }
  .dean i {
    font-size: 2rem;
  }
  .dean {
    margin: 2%;
  }
}
